@media screen and (max-width: 1100px){
  .nav-direct-link {
    font-size: 0.55em !important;
  }
  #nav-bar {
    font-size: smaller !important;
  }
}

@media screen and (max-width: 800px){
  #nav-bar {
    display: block !important;
    height: var(--nav-responsive-height) !important;
  }
  .nav-item {
    display: block !important;
    height: auto !important;
    margin: auto !important;
    width: auto !important;
  }
  .nav-direct-link {
    height: auto !important;
    width: 50% !important;
    margin-top: 20px !important;
    font-size: large !important;
  }

  body {
    background-size: 170% !important;
  }
}

#nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--nav-height);
  align-items: center;
  overflow: hidden;
  display: flex;
  background-color: #212100;
  justify-content: center;
}

.nav-brand {
  display: flex;
  justify-content: center;
  font-family: 'Patrick Hand', cursive;
  white-space: nowrap;
  font-size: 2em;
  font-weight: bolder;
  color: white;
  text-decoration: none;
  text-align: center;
}

.nav-a {
  text-decoration: none;
  white-space: nowrap;
  color: white;
  font-size: 1.25em;
  font-weight: bolder;
  width: 100%;
  padding: 0 10px;
}

.nav-item {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: auto;
  margin: 0px 0.5%;
}

.nav-logo {
  width: 2%;
  height: 40%;
}

.nav-direct-link {
  display: flex;
  justify-content: center;
  text-wrap: wrap;
  background-color: inherit;
  font-size: medium;
  color: orange;
  width: auto;
  padding: 0 1%;
  margin-left: 2%;
  border-radius: 5px;
  border: solid 2px orange;
  text-align: center;
}

#nav-bar .icon {
  display: none;
}

.nav-a:hover {
  color: black;
  background-color: white;
}
.nav-direct-link:hover {
  background-color: orange;
  color: #212121;
}

.nav-download-link {
  text-decoration-line: underline;
  color: orange;
}

.nav-download-link:hover {
  text-decoration-line: none;
  color: #212121;
  background-color: orange;
}