@media screen and (max-width: 1100px){
    .intro .subsection {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    img.profile-pic {
        margin-top: 0% !important;
    }

  }

@media screen and (max-width: 800px) {
    .sections-container {
        padding: 0 !important;
    }
    .section {
        padding: var(--nav-responsive-height) 5px 0 2px !important;
    }
    .content {
        display: block !important;
    }
    .content p {
        text-align: center !important;
    }
    img.profile-pic {
        margin: auto !important;
    }
    .lets-connect a {
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .intro .subsection{
        padding: 0% !important;
    }
    
    
}

.section {
    padding: var(--nav-height) 20px var(--nav-responsive-height) 20px;
    border-radius: 10px;
}

.subsection {
    background-color: #212100;
    border-radius: 5px;
    padding: 5%;
}

.section h2 {
    color: orange;
    margin: 0;
}

.sections-container {
  padding: 0 10%;
}

.content {
    display: flex;
    justify-content: center;
}

.about-me img {
    width: 100%;
}

.content p {
    text-align: left;
    margin-top: 0;
}

.content .text {
    display: block;
}

img.profile-pic {
    display: block;
    width: 50%;
    border: solid 5px orange;
    border-radius: 20px;
    margin: auto 1%;
}

.lets-connect img {
    width: 50px;
    height: 50px;
    margin: 0 5px;
}

.lets-connect .content {
    padding: 0 10%;
}

.lets-connect a {
    display: flex;
    margin: 20px;
    text-decoration: none;
    color: white;
    text-wrap: nowrap;
    align-items: center;
}

.lets-connect a:hover {
    color: orange;
}

.journey .content {
    display: block;
}

.journey-stage {
    display: flex;
    width: 100%;
    margin-bottom: 10%;
}

.journey-image-container {
    height: auto;
    display: block;
    width: 20%;
    margin-right: 5%;
}

.journey-image-container img {
    width: 100%;
    height: auto;
    border-radius: 10%;
    border: solid 3px orange;
    background-color: white;
    padding: 0%;
    margin: 0%;
}

.journey-stage .text {
    width: 80%;
}
.journey-separator {
    margin-top: auto;
    margin-bottom: auto;
    height: 110%;
    border-left: solid 3px orange;
    margin-left: 20%;
}

p.section-intro {
    color: orange;
}

p.emphasis {
    font-size: larger;
    font-weight: 700;
    color: orange;
}

.text a {
    color: orange;
}

.intro {
    padding-bottom: var(--intro-adjustment-padding-bottom);
}
